import React, { useState } from 'react';
import { homeData } from '../../mock-ups/home.data';
import { useIsMobile } from '../../utils/hooks/use-is-mobile';
import { Container, MBContent, Step } from './home-content.styled';
import IconFrame from '../icon-frame/icon-frame.comp';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../button/button.comp';
import i18next from '../i18n';
import { navigate } from 'gatsby';


export default function HomeContent() {
	const [current, setCurrent] = useState(0);
	const isMobile = useIsMobile();

	const next = () => {
		setCurrent((prev) => {
			if (prev <= 1) return prev + 1;
			return prev;
		});
	};

	const closeWarning = () => {
		document.getElementsByClassName('ai-warning')[0].style.display = 'none';
	}

	return (
		<Container>
			<div className='content'>
				<div className='wrapper'>
					{homeData.map(({ id, icon, title }) => {
						return (
							<React.Fragment key={id} >
								
								<div onClick={ () => { navigate('/get-started') } }>
									<IconFrame icon={icon} title={title} />
								</div>
								{id < 3 && (
									<img
										className='arrow'
										src='/images/icons/right-arrow.svg'
										alt=''
									/>
								)}
							</React.Fragment>
						);
					})}
				</div>
				<Button
					to='/get-started'
					text={ i18next.t('button.get_started') }
					size='lg'
					className='btn get-started-btn'
					variant={isMobile ? 'solid' : 'outline'}
				/>
			</div>

			<div className="ai-banner ai-warning" onClick={ () => closeWarning() } > 
				{ i18next.t('layout.warning') } 
				<CloseIcon />
			</div>		

			<MBContent>
				<div className='current-step'>
					
				<div onClick={ () => { navigate('/get-started') } }>
					<IconFrame icon={homeData[current].icon} className='icon-frame'/>
					</div>
					<h6>{homeData[current].mbTitle}</h6>
					<p>{homeData[current].mbContent}</p>
				</div>
				{current <= 1 && <Button text='Get Started' className='btn next-btn' to='/get-started' />}
			</MBContent>
		</Container>
	);
}
