import React, { useEffect, useState } from 'react';
import PageLayout from '../layouts/page/page.layout';
import HomeContent from '../components/home-content/home-content.comp';
import { useIsMobile } from '../utils/hooks/use-is-mobile';
import { useData } from '../context/data';
import { Banner, StaticBanner } from 'material-ui-banner';
import i18next from '../components/i18n';

const IndexPage = ({location}) => {
	const isMobile = useIsMobile();
	// const { emptyData } = useData();

	const [company, setCompany] = useState('');

	useEffect(() => {
		// Clears Context Data
		// emptyData()

		const isBrowser = () => typeof window !== "undefined"

		// Example: https://passportprintsplus.com/ becomes passportprintsplus_com
		const domain = isBrowser() && window.location.hostname
			.replace('www.', '')

		switch (domain) {
			case 'passportprintsnow.com':
				setCompany('CVS Pharmacy')
				break;
			case 'photoidprints.com':
				setCompany('FotoFirst Passports')
				break;
			case 'passportprint.com':
				setCompany('Walgreens')
				break;
			case 'passportprintsplus.com':
				setCompany('Walmart')
				break;
			case 'passportprint.co.nz':
			case 'passportprint.co':
				setCompany('Warehouse Stationery Passports')
				break;
			default:
				isBrowser() && console.error(`Configuration error, cant find domain ${domain}.`);
			}
	})

	return (
		<>
		<PageLayout
			subTopic={
				isMobile ? (
					'Three Simple Steps'
				) : (
					<>
					    { i18next.t('index.subtitle.default_val') + ' ' + company  } 
					</>
				)
			}
			location={location}
		>
			<HomeContent />
				
		</PageLayout>
		
		</>
	);
};

export default IndexPage;
