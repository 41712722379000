
import i18next from '../components/i18n'


export const homeData = [
	{
		id: 1,
		title: i18next.t('home.desktop.title.placement_guide'),
		mbTitle: i18next.t('home.mb.title.placement_guide'),
		mbContent: i18next.t('home.mb.content.placement_guide'),
		icon: 'images/icons/avatar.svg',
	},

	{
		id: 2,
		title: i18next.t('home.desktop.title.image_verification'),
		mbTitle: i18next.t('home.mb.title.image_verification'),
		mbContent: i18next.t('home.mb.content.image_verification'),
		icon: 'images/icons/checker.svg',
	},

	{
		id: 3,
		title: i18next.t('home.desktop.title.send_store'),
		mbTitle: i18next.t('home.mb.title.send_store'),
		mbContent: i18next.t('home.mb.content.send_store'),
		icon: 'images/icons/print.svg',
	},
];
